<template>
  <div class="timer-units" :class="{ hideUnits }">
    <div class="timer-unit">
      <div class="number">{{ timeLeft.seconds }}</div>
      <div class="unit">שניות</div>
    </div>
    <div class="timer-unit"><div class="divider">:</div></div>
    <div class="timer-unit">
      <div class="number">{{ timeLeft.minutes }}</div>
      <div class="unit">דקות</div>
    </div>
    <div class="timer-unit"><div class="divider">:</div></div>
    <div class="timer-unit">
      <div class="number">{{ timeLeft.hours }}</div>
      <div class="unit">שעות</div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'SubscriptionMbgTimerCounter',
  data() {
    return {
      timeLeft: {
        hours: '00',
        minutes: '00',
        seconds: '00',
      },
      interval: null,
    };
  },
  props: {
    endDate: { type: Object, require: true },
    hideUnits: { type: Boolean, default: false },
  },
  created() {
    this.interval = setInterval(this.updateTimeLeft, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    updateTimeLeft() {
      const remainingDuration = moment.duration(this.endDate.diff(moment()));
      this.timeLeft = {
        hours: this.withLeadingZeros(Math.floor(remainingDuration.asHours())),
        minutes: this.withLeadingZeros(Math.floor(remainingDuration.asMinutes() % 60)),
        seconds: this.withLeadingZeros(Math.floor(remainingDuration.asSeconds() % 60)),
      };
    },
    withLeadingZeros(num) {
      return num < 10 ? `0${num}` : num;
    },
  },
};
</script>

<style scoped lang="scss">
.timer-units {
  .timer-unit {
    color: white;
    display: inline-block;
    text-align: center;
    vertical-align: top;

    .number, .divider {
      margin: 16px 0 8px;
      font-size: 50px;
    }

    .number {
      min-width: 58px;
    }

    .unit {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &.hideUnits .timer-unit .unit {
    display: none;
  }
}
</style>
