<template>
  <div class="offer-timer">
    <div class="timer-header">ההצעה נגמרת בעוד</div>
    <div class="timer-content">
      <div class="rotating-sun">
        <img :src="require('@/assets/sun4.svg')" />
      </div>
      <subscription-mbg-timer-counter :end-date="mbgOfferEndDate" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SubscriptionMbgTimerCounter
  from '@/pages/responsive-pages/authenticated/trial/money-back-offer/SubscriptionMbgTimerCounter';

export default {
  name: 'SubscriptionMbgTimer',
  components: { SubscriptionMbgTimerCounter },
  computed: {
    ...mapGetters('subscriptionState', ['mbgOfferEndDate']),
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

@keyframes spin {
  from {
    transform: rotate(-25deg);
  }
  to {
    transform: rotate(335deg);
  }
}

.offer-timer {
  margin: 11px 0px 0;
  font-size: 16px;
  font-family: SimplerPro,sans-serif;
  text-align: center;
  cursor: pointer;
  line-height: 24px;

  .timer-header {
    position: relative;
    height: 30px;
    vertical-align: middle;
    z-index: 1;
    bottom: -15px;
    margin: auto;
    width: max-content;
    font-weight: bold;
    background: white;
    border: 1px solid $riseup_orange;
    padding: 0 10px;
    text-align: center;
    border-radius: 5px;
    font-size: 14px;
    line-height: 30px;
    color: $riseup_orange;
  }

  .timer-content {
      position: relative;
      height: 111px;
      padding-top: 26px;
      overflow: hidden;
      background: $riseup_orange;
      border-radius: 10px;

      .timer-unit {
        color: white;
        display: inline-block;
        text-align: center;
        vertical-align: top;

      .number {
        margin: 16px 0 8px;
        font-size: 50px;
      }

      .unit {
        font-size: 12px;
        line-height: 16px;
        min-width: 58px;
      }
    }

    .rotating-sun {
      z-index: 1;
      position: absolute;
      left: -65px;
      top: 8px;

      img {
        width: 100px;
        animation: spin 20s linear infinite;
      }
    }
  }
}
</style>
