<template>
  <check-box :toggle="toggleEnabledTerms">
    <template v-slot:title>
      <div class="margin-right-sm">
        קראתי ואישרתי את
        <span class="link" @click="openTerms">תנאי המסלול המלאים.</span>
      </div>
    </template>
  </check-box>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'TermAndConditions',
  components: {
    CheckBox: BaseUI.CheckBox,
  },
  props: {
    toggleEnabledTerms: {
      type: Function,
      required: true,
    },
    linkToTerms: {
      type: String,
      required: true,
    },
  },
  methods: {
    openTerms() {
      window.open(this.linkToTerms, '_blank');
    },
  },
};
</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.link {
  text-decoration: underline;
  cursor: pointer;
  color: $riseup_blue;
}
</style>
