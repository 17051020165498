<template>
  <div>
    <img :src="require('@/assets/hero-login.svg')">

    <div class="padding-ml dormant-mbg ri-large-body">
      <div class="ri-font-16 margin-bottom-m">הצעה מיוחדת לזמן מוגבל</div>
      <div class="ri-font-24 ri-font-weight-bold margin-bottom-l">לא הרווחת – לא שילמת!</div>
      <div class="">
        <div class="margin-bottom-ml">
          לקוחות רייזאפ משפרים את התזרים ב־
          <span class="ri-font-weight-bold">2,473 ₪ בממוצע כל חודש</span>
          אחרי שלושה חודשים בשירות.
        </div>
      </div>
      <div class="margin-bottom-ml">
        ולנו יש הצעה מיוחדת: התחילו לשלם על המנוי במסגרת חלון הזמן, ואם בעוד שלושה חודשים תרצו לעזוב, נחזיר לכם את הכסף ששילמתם.
      </div>
      <div class="ri-font-weight-bold margin-bottom-sm">
        עד כדי כך אנחנו בטוחים שזה ישתלם!
      </div>
      <subscription-mbg-timer :offer-end-date="timerEndTime"/>

      <div class="margin-top-l ri-label">
        אחרי שלושה חודשים נשלח תזכורת, ותוכלו להחליט תוך
        <b>10 ימים</b>
        אם להמשיך או לבטל ולקבל את הכסף בחזרה.
      </div>

      <TermAndConditions
          class="margin-top-ml ri-font-14"
          :toggle-enabled-terms="toggleEnabledTerms"
          :link-to-terms="linkToTerms"/>

      <riseup-button
          class="margin-top-m rejoin-button"
          :action="goToPay"
          title="אני בעניין!"
          :loading="isLoading"
          :disabled="!isReadyToPay"/>

    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import moment from 'moment';
import router from '@/router';
import DDLogs from '@/DDLogs';
import BaseUi from '@riseupil/base-ui';
import SubscriptionMbgTimer from '@/pages/responsive-pages/authenticated/trial/money-back-offer/SubscriptionMbgTimer';
import CustomersApi from '@/api/CustomersApi';
import Segment from '../../../Segment';
import TermAndConditions from './TermAndConditions.vue';

const SECOND = 1000;

export default {
  name: 'DormantMBG',
  components: {
    SubscriptionMbgTimer,
    TermAndConditions,
    RiseupButton: BaseUi.RiseupButton,
  },
  async created() {
    Segment.trackUserGot('DormantMBG_PageEntered');
    if (!this.isDormant) {
      Segment.trackUserGot('DormantMBG_PageEntered_NotDormantCustomer');
      await router.push({ path: 'home' });
    } else {
      this.setDormantWithMBGOffer(true);
      await this.initTimer();
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  data() {
    return {
      timerEndTime: undefined,
      timer: undefined,
      termsEnabled: false,
      linkToTerms: 'https://www.riseup.co.il/termsofuse/?scrollTo=8',
      isLoading: false,
    };
  },
  computed: {
    ...mapState('websocket', ['socketMessage']),
    ...mapGetters('subscriptionState', ['isDormant', 'mbgOfferEndDate']),
    isTimeUp() {
      return moment().isAfter(this.mbgOfferEndDate);
    },
    isReadyToPay() {
      return this.termsEnabled && !this.isTimeUp;
    },
  },
  methods: {
    ...mapActions('subscriptionState', ['fetchSubscriptionState']),
    ...mapMutations('subscriptionState', ['setDormantWithMBGOffer']),
    toggleEnabledTerms() {
      this.termsEnabled = !this.termsEnabled;
    },
    async initTimer() {
      await this.fetchSubscriptionState();
      if (!this.mbgOfferEndDate) {
        await this.offerMBG();
      }
      this.timer = setInterval(this.checkTimer, SECOND);
      Segment.trackUserGot('DormantMBG_TimerStarted', { timerEndTime: this.mbgOfferEndDate });
    },
    checkTimer() {
      if (this.isTimeUp) {
        Segment.trackUserInteraction('DormantMBG_timesUp', { timerEndTime: this.mbgOfferEndDate });
        clearInterval(this.timer);
        router.push({ path: 'reset-cashflow' });
      }
    },
    // removing the MBG for the EOY sale
    async offerMBG() {
      // await BenefitsApi.offeredMoneyBackGuarantee();
      await this.fetchSubscriptionState();
    },
    async goToPay() {
      Segment.trackUserInteraction('DormantMBG_ClickOnRejoin');
      await CustomersApi.resetForDormant();
      DDLogs.log('Waiting for version in dormant-money-back-guarantee page');
      this.isLoading = true;
    },
  },
  watch: {
    async socketMessage(newValue, oldValue) {
      if (this.isLoading && newValue !== oldValue) {
        DDLogs.log('Done waiting for version in cashflow reset page, moving to next page');
        await this.offerMBG();
        await router.push({ path: 'subscription' });
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.dormant-mbg {
  line-height: 24px;

  .line-height-20 {
    line-height: 24px;
  }

  .link {
    text-decoration: underline;
    cursor: pointer;
    color: $riseup_blue;
  }

  .rejoin-button {
    width: 100%;
    height: 70px;
  }
}
</style>
